
import './App.css';
import GetDataComponent from './component/getData1';
import * as React from 'react';

function App() {
  return (
    <div className="App">
      <GetDataComponent />
    </div>
  );
}

export default App;