import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../config";
import * as React from 'react';

import Select from 'react-select';

const options = [
  { value: 'hour1', label: '5 min Data' },
  { value: 'hour24', label: '1 Hour Data' },
];


const GetData1Component = () => {

  const [sortArr, setSortArray] = useState([]);
  const [isLoading, setIsLoading] = useState(1);  
  const [selectedOption,setSelectedOption] = useState(options[0]);
  useEffect(() => {
    console.log(BACKEND_URL)
    let hourPath = 'getdata1';
    if (selectedOption.value == 'hour1') {
      hourPath = 'getdata1';
    } else {
      hourPath = 'getdata24';
    }
    setIsLoading(1);
    axios.get(`${BACKEND_URL}/${hourPath}`, {
      headers: {
      'Content-Type': "application/json",
      'Accept': "application/json",
      }  
    }).then((response) => {
      console.log(response)
      setSortArray(response.data);
      setIsLoading(0)
    });
  }, [selectedOption])

  const handleChange = (selOption) => {
    setSelectedOption(selOption);
    console.log(selOption)
  };
  
  
  return (
    // Render your component UI here
    <div>
      {isLoading ? (
          <>Loading...</>
        ) : (      
        <div>        
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
          />
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Symbol</th>
                <th>5min Tx</th>
                <th>5min Holders</th>
                <th>1hour Tx</th>
                <th>1hour Holders</th>
              </tr>
            </thead>
            <tbody>
              {sortArr.filter((val, ind) => ind <20).map((item, index) => {
                return (<tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.symbol}</td>
                  <td>{item.utxcount}</td>
                  <td>{item.utotalholders}</td>
                  <td>{item.u24txcount == 0 && item.utxcount != 0 ? item.utxcount : item.u24txcount}</td>
                  <td>{item.u24totalholders == 0 && item.utotalholders != 0 ? item.utotalholders : item.u24totalholders}</td>
                  <td>{
                    <a href={"https://dexscreener.com/ethereum/" + item.id} target="_blank" rel="noreferrer">
                      <button>View on Dex</button>
                    </a>
                  }</td>
                </tr>)
              })}
              
            </tbody>
          </table>
        </div>
      )}
        
    </div>
    
  );
};

export default GetData1Component